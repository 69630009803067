import React from 'react';
import styles from './ColorThemeSection.module.scss';
import ModelSectionLabel from '../../../components/ModalSectionLabel/ModalSectionLabel';
import colorZone from "../../../../../../ColorTheme/colorsList";
import {useColor} from "../../../../../../ColorTheme/ThemeContext";
import {setData} from "../../../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../../../services/data/constants";

const ColorThemeSection = () => {
    const {changePalette} = useColor();

    const changePallet = (value) => {
        changePalette(value);
        setData(CATEGORIES.SETTINGS, {colorTheme: value}, true);
    };

    return (
        <div className={styles.ColorThemeSection}>
            <ModelSectionLabel name="Color Theme"/>
            <div className={styles.gridContainer}>
                <div id={styles.cell1}>
                    <section onClick={() => changePallet(1)}>
                        <div style={{backgroundColor: `rgb(${colorZone.color1.primary})`}}></div>
                        <div style={{backgroundColor: `rgb(${colorZone.color1.accent})`}}></div>
                    </section>
                </div>
                <div id={styles.cell2}>
                    <section onClick={() => changePallet(2)}>
                        <div style={{backgroundColor: `rgb(${colorZone.color2.primary})`}}></div>
                        <div style={{backgroundColor: `rgb(${colorZone.color2.accent})`}}></div>
                    </section>
                </div>
                <div id={styles.cell3}>
                    <section onClick={() => changePallet(3)}>
                        <div style={{backgroundColor: `rgb(${colorZone.color3.primary})`}}></div>
                        <div style={{backgroundColor: `rgb(${colorZone.color3.accent})`}}></div>
                    </section>
                </div>
                <div id={styles.cell4}>
                    <section onClick={() => changePallet(4)}>
                        <div style={{backgroundColor: `rgb(${colorZone.color4.primary})`}}></div>
                        <div style={{backgroundColor: `rgb(${colorZone.color4.accent})`}}></div>
                    </section>
                </div>
            </div>
        </div>
    );
};


export default ColorThemeSection;
