import React from 'react';
import styles from './AboutPage.module.scss';
import appStyles from '../../../App.scss';
import PublicHeader from "../components/PublicHeader/PublicHeader";

const
    AboutPage = () => (
        <div className={styles.AboutPage}>
            <PublicHeader pageName="About"/>
            AboutPage Component
        </div>
    );

export default AboutPage;
