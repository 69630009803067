import React, {useEffect, useRef, useState} from 'react';
import styles from './LoadingBar.module.scss';
import {useColor} from "../../../ColorTheme/ThemeContext";

const LoadingBar = ({isLoading}) => {
    const {colors} = useColor();
    const loadingBarRef = useRef(null);
    const barRef = useRef(null);
    const [shouldDisplay, setShouldDisplay] = useState(false);

    useEffect(() => {
        let timeoutId;

        // If loading changes from false to true, set a timeout to hide loading bar after 2 seconds
        if (isLoading) {
            timeoutId = setTimeout(() => {
                setShouldDisplay(true);
            }, 1000); // 2 seconds
        } else {
            // If loading changes from true to false, clear the timeout and hide loading bar immediately
            clearTimeout(timeoutId);
            setShouldDisplay(false);
        }

        return () => clearTimeout(timeoutId); // Clean up timeout on component unmount or state change
    }, [isLoading]);

    useEffect(() => {
        const loadingBarElement = loadingBarRef.current;
        const barElement = barRef.current;

        if (loadingBarElement && barElement) {
            loadingBarElement.style.display = shouldDisplay ? 'block' : 'none';
            loadingBarElement.style.backgroundColor = `rgba(${colors.primary}, .3)`;
            barElement.style.backgroundColor = `rgba(${colors.accent}, .7)`;
        }
    }, [shouldDisplay, colors.primary, colors.accent]);

    return (
        <div ref={loadingBarRef} className={styles.LoadingBar}>
            <div ref={barRef} className={styles.bar}></div>
        </div>
    );
};

export default LoadingBar;
