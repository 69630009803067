import React, {useEffect, useState} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './MainWindow.module.scss';
import StudentPage from "../../course/StudentPage/StudentPage";
import Lesson1 from "../../course/Lesson1/Lesson1";
import Lessons from "../../course/Lessons/Lessons";

const MainWindow = ({resizeState}) => {
    const [left, setLeft] = useState("5%");
    const [width, setWidth] = useState("95%")

    useEffect(() => {
        if (resizeState) {
            setLeft('15%');
            setWidth("85%")

        } else {
            setLeft("5%");
            setWidth("95%")
        }
    }, [resizeState]); // Include resizeState in the dependency array

    return (
        <div className={styles.MainWindow} style={{left: left, width: width}}>
            <div className={styles.content}>
                <Routes>
                    <Route index element={<StudentPage/>}/>
                    <Route path="*" element={<Navigate to="./"/>}/>
                    <Route path="lesson/*" element={<Lessons/>}/>
                </Routes>
            </div>
        </div>
    );
};

MainWindow.propTypes = {
    resizeState: PropTypes.bool
};

export default MainWindow;
