import React from 'react';
import styles from './AuthenticatePage.module.scss';
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import BackgroundAnimation1 from "../../../components/BackgroundAnimation1/BackgroundAnimation1";

const AuthenticatePage = ({isSignIn}) => (
    <>
        <BackgroundAnimation1/>
        <div className={styles.AuthenticatePage}>
            {isSignIn ? <SignIn/> : <SignUp/>}
        </div>
    </>
);


export default AuthenticatePage;
