import {post, get} from '@aws-amplify/api';
import awsmobile from "../../aws-exports";
import apiConfig from "./api-config";

async function MainApiService(method, path, pathParams, queryParams = {}, body) {
    try {
        let name = awsmobile.aws_cloud_logic_custom[0].name;
        let message;
        switch (method) {
            case apiConfig.methods.post:
                message = post({
                    apiName: name,
                    path: pathParams ? `${path}/${pathParams}` : path,
                    options: {
                        body: body,
                    },
                });
                break;
            case apiConfig.methods.get:
                message = get({
                    apiName: name,
                    path: pathParams ? `${path}/${pathParams}` : path,
                    options: {
                        queryParams: queryParams,
                    },
                });
                break;
            default:
                console.error(`Application layer 3 error - Unsupported method type: ${method}`);
        }
        return await message.response;
    } catch (error) {
        console.log(`Cannot reach API. error: ${error}`);
    }
}

export default MainApiService;
