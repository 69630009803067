import React, {useRef, useState} from 'react';
import styles from './SignIn.module.scss';
import authStyles from '../AuthenticatePage.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {Button, LinearProgress, TextField, Typography} from "@mui/material";
import SignInServ from "../../../../services/API_Resources/authentication/sign-in-service";
import loginGraphic from "../../../../assets/AuthenticationPages/loginPage.png"

const SignIn = () => {
        const formRef = useRef(null);
        const [isLoading, setIsLoading] = useState(false);
        const [userFeedback, setUserFeedback] = useState("");
        const navigate = useNavigate();

        async function handleSubmit(event) {
            event.preventDefault();

            const username = event.target.elements.username.value;
            const password = event.target.elements.password.value;


            try {
                setIsLoading(true); // Start loading
                const response = await SignInServ(username, password);
                setIsLoading(false); // Stop loading upon response
                if (response) {
                    // console.log("login was successful");
                    setUserFeedback("");
                    navigate('/dashboard');
                } else {
                    // console.log("login was unsuccessful");
                    setUserFeedback("The username or password is incorrect.");
                }

            } catch (error) {
                console.error(`Application layer 1 error - Sign In: ${error}`);
            }
        }


        return (
            <div className={`${styles.SignIn} ${authStyles.mainBox}`}>
                <div className={authStyles.formBox}>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <Typography className={authStyles.title} variant="h4">Welcome back</Typography>
                        <div className={authStyles.accountType}>
                            <p>Don't have an account,&nbsp;
                                <span>
                                    <Link to="/create">Create one.</Link>
                                </span>
                            </p>
                        </div>
                        <div className={authStyles.textbox}>
                            <input id="username"
                                   name="username"
                                   placeholder="Username"
                                   required
                            />
                            <input id="password"
                                   name="password"
                                   type="password"
                                   required
                                   placeholder="password"
                            />
                        </div>
                        <p className={authStyles.message}>{userFeedback}</p>
                        <div className={authStyles.submit}>
                            <button disabled={isLoading}>Login</button>
                            {isLoading && <LinearProgress color="primary"/>}
                        </div>
                    </form>
                </div>
                <div className={authStyles.graphicBox}>
                    <div className={authStyles.imgPlaceholder}>
                        <img src={loginGraphic} alt={"Image of a student logging into a computer"}/>
                    </div>
                </div>
            </div>
        );
    }
;

export default SignIn;
