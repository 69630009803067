import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import styles from './HeadPanel.module.scss';
import MenuBackButton from "../../../../components/MenuBackButton/MenuBackButton";
import {useColor} from '../../ColorTheme/ThemeContext';
import MenuButton from "../components/MenuButton/MenuButton";

const HeadPanel = ({resizeHandler}) => {
    const {colors} = useColor();
    useEffect(() => {
        document.querySelector(`.${styles.HeadPanel}`).style.backgroundColor = `rgba(${colors.primary}, .2)`;
    }, [colors]);


    return (
        <div className={styles.HeadPanel}>
            <div className={styles.sizeControl}>
                <button onClick={() => {
                    resizeHandler()
                }}>
                    <MenuButton/>
                </button>
            </div>
            <div className={styles.mainInfo}>
                <div className={styles.titleText}>
                    <p>
                        Read aloud
                    </p>
                </div>
                <div className={styles.progressInfo}>
                    <p>
                        Progress saved
                    </p>
                </div>
            </div>
        </div>
    );
};

HeadPanel.propTypes = {
    resizeHandler: PropTypes.func,
};

export default HeadPanel;
