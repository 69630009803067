import React from 'react';
import styles from './UnAuthorizedPage.module.scss';

const UnAuthorizedPage = () => (
    <div className={styles.UnAuthorizedPage}>
        UnAuthorizedPage Component
    </div>
);


export default UnAuthorizedPage;
