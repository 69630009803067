import React from 'react';
import styles from './HomeHead.module.scss';
import homeStyles from '../HomePage.module.scss';
import {Typography} from "@mui/material";
import background from "../../../../assets/HomePage/backgroundHead.png";
import subject from "../../../../assets/HomePage/people.png";

const HomeHead = () => (
    <div className={`${styles.HomeHead} ${homeStyles.wrapper}`}>
        <div className={"margin"}>
            <div className={`${styles.content} ${homeStyles.content}`}>
                <div className={`${styles.text} ${homeStyles.text}`}>
                    <div className={styles.textContainer}>
                        <div className={styles.titleText}><Typography variant="h1">Powering Up</Typography></div>
                        <div className={styles.subText}>
                            <Typography variant="h5">
                                Advantage old had otherwise sincerity dependent additions. It in adapted natural hastily
                                is
                                justice. Six draw you him full not mean
                            </Typography>
                        </div>

                    </div>
                </div>
                <div className={styles.boxHead}>
                    <img className={styles.subject} src={subject} alt={"group of kids"}/>

                    {/*<img className={styles.background} src={background} alt={"background design"}/>*/}
                </div>
            </div>
        </div>

    </div>
);


export default HomeHead;
