import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';
import styles from './ModalBox.module.scss';
import SettingsModal from '../SettingsModal/SettingsModal';
import ModalHead from '../components/ModalHead/ModalHead';
import AccountModal from '../AccountModal/AccountModal';

const ModalBox = ({mode, onResetMode}) => {
    const [element, setElement] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const overlaySpring = useSpring({
        opacity: isVisible ? 1 : 0,
        config: {tension: 300, friction: 20},
        onRest: () => {
            if (!isVisible) {
                setIsAnimating(false);
                setElement(null);
                if (onResetMode) {
                    onResetMode(); // Call the reset function passed from the parent
                }
            }
        }
    });

    const modalSpring = useSpring({
        transform: isVisible ? `translateY(0)` : `translateY(-50%)`,
        opacity: isVisible ? 1 : 0,
        config: {tension: 300, friction: 20}
    });

    useEffect(() => {
        if (!mode) {
            return;
        }
        switch (mode) {
            case 'settings':
                setElement(<SettingsModal/>);
                break;
            case 'account':
                setElement(<AccountModal/>);
                break;
            default:
                setElement(<SettingsModal/>);
        }
        setIsAnimating(true);
        setIsVisible(true);
    }, [mode]);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <animated.div
            className={styles.ModalBox}
            style={{
                display: isAnimating ? 'flex' : 'none',
                ...overlaySpring
            }}
            onClick={handleClose}
        >
            <animated.div
                className={styles.modal}
                style={modalSpring}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalHead name={mode} onBack={handleClose}/>
                <div className={styles.margin}>
                    {element}
                </div>
            </animated.div>
        </animated.div>
    );
};

ModalBox.propTypes = {
    mode: PropTypes.string,
    onResetMode: PropTypes.func
};

export default ModalBox;
