import React from 'react';
import {Route, Navigate} from 'react-router-dom';
import PublicLayout from './Public/PublicLayout';
import PrivateLayout from './Private/PrivateLayout';
import HomePage from "./Public/HomePage/HomePage";
import AboutPage from "./Public/AboutPage/AboutPage";
import ContactPage from "./Public/ContactPage/ContactPage";
import AuthenticatePage from "./Public/AuthenticatePage/AuthenticatePage";
import DashboardPage from "./Private/DashboardPage/DashboardPage";
import UnAuthorizedPage from "./Public/UnAuthorizedPage/UnAuthorizedPage";
import NotFoundPage from "./Public/NotFoundPage/NotFoundPage";


const routes = [
    {
        path: '/',
        element: <PublicLayout/>,
        children: [
            {index: true, element: <HomePage/>},
            {path: '*', element: <NotFoundPage/>},
            {path: 'about', element: <AboutPage/>},
            {path: 'contact', element: <ContactPage/>},
            {path: 'login', element: <AuthenticatePage isSignIn={true}/>},
            {path: 'create', element: <AuthenticatePage isSignIn={false}/>},
            {path: '401', element: <UnAuthorizedPage/>}
        ]
    },
    {
        path: '/',
        element: <PrivateLayout/>,
        children: [
            {path: 'dashboard/*', element: <DashboardPage/>}
        ]
    }
];

export default routes;
