import {CATEGORIES} from './constants';

let data = {
    [CATEGORIES.SESSION]: {authenticated: false},
    [CATEGORIES.DASHBOARD]: {},
    [CATEGORIES.SETTINGS]: {},
    [CATEGORIES.CHECKIN]: {},
    [CATEGORIES.LESSONS]: {}
};

// Initialize data from sessionStorage if available
function loadData() {
    Object.keys(data).forEach(category => {
        const storedData = sessionStorage.getItem(category);
        if (storedData) {
            data[category] = JSON.parse(storedData);
        }
    });
}

// Save data to sessionStorage
function saveData(category) {
    if (data.hasOwnProperty(category)) {
        sessionStorage.setItem(category, JSON.stringify(data[category]));
    }
}

function setData(category, newData, append = false) {
    if (data.hasOwnProperty(category)) {
        if (append) {
            deepMerge(data[category], newData);
        } else {
            data[category] = newData;
        }
        saveData(category); // Save the updated data to sessionStorage
    } else {
        console.error(`Category "${category}" does not exist.`);
    }
}

function getData(category) {
    if (data.hasOwnProperty(category)) {
        if (Object.keys(data[category]).length === 0) {
            const storedData = sessionStorage.getItem(category);
            if (storedData) {
                data[category] = JSON.parse(storedData);
            }
        }
        return data[category];
    } else {
        console.error(`Category "${category}" does not exist.`);
        return null;
    }
}

function deepMerge(target, source) {
    for (const key in source) {
        if (source[key] instanceof Object && key in target) {
            target[key] = deepMerge(target[key], source[key]);
        } else {
            target[key] = source[key];
        }
    }
    return target;
}

// Load initial data from sessionStorage once when the module is first imported
loadData();

export {setData, getData};
