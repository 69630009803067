const colorZone = {
    color0: {primary: "65, 65, 65", accent: "20, 20, 20"},
    color1: {primary: "201, 0, 0", accent: "22, 23, 100"},
    color2: {primary: "9, 122, 83", accent: "148, 11, 58"},
    color3: {primary: "0, 31, 201", accent: "161, 12, 123"},
    color4: {primary: "201, 89, 0", accent: "71, 22, 100"},


};

export default colorZone;
