import React, {useEffect} from 'react';
import styles from './L1S5a.module.scss';
import parent from "../../../Lessons/Lessons.module.scss";
import {Link} from "react-router-dom";
import {getData} from "../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../services/data/constants";
import VideoPlayer from "../../../Lessons/components/VideoPlayer/VideoPlayer";

const L1S5a = ({thisPage, thisLesson}) => {
    const lesson = `lesson${thisLesson}`;
    const pageID = {
        section: 5,
        page: 1
    };
    useEffect(() => {
        thisPage(pageID);
    }, []);

    const handleCompletion = () => {
        const completion = getData(CATEGORIES.LESSONS)
        completion[lesson].pages[`page${pageID.section}/${pageID.page}`] = {isComplete: true}
    }

    return (
        <div className={styles.L1S2a}>
            <VideoPlayer title={"Summary Video"}/>
            <div className={`${parent.buttonGroupRight} ${parent.buttonGroup}`}>
                <Link to={"../../section/4/a"} as={"button"}>
                    <button className={`${parent.backButton}`}>Back</button>
                </Link>
                <Link to={"../../section/6/a"} as={"button"}>
                    <button
                        onClick={handleCompletion}
                        className={`${parent.nextButton} button-ok-course`}>Next
                    </button>
                </Link>
            </div>
        </div>
    );
}


export default L1S5a;
