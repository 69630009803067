import React, {useRef, useState, useEffect} from 'react';
import styles from './VideoPlayer.module.scss';
import parent from '../../Lessons.module.scss';
import PlayPauseButton from "./PlayPauseButtons/PlayPauseButton";

const VideoPlayer = ({title}) => {
    const videoRef = useRef(null);
    const progressRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const video = videoRef.current;
        const updateDuration = () => setDuration(video.duration);

        video.addEventListener('loadedmetadata', updateDuration);

        return () => {
            video.removeEventListener('loadedmetadata', updateDuration);
        };
    }, []);

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };


    const handleProgress = () => {
        const video = videoRef.current;
        const progress = progressRef.current;
        const current = video.currentTime;
        setCurrentTime(current);
        progress.value = (current / video.duration) * 100;
    };

    const handleProgressChange = (e) => {
        const video = videoRef.current;
        const newTime = (e.target.value / 100) * video.duration;
        video.currentTime = newTime;
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className={styles.VideoPlayer}>
            <div className={parent.content}>
                <h5>{title}</h5>
            </div>
            <div className={styles.wrapper}>
                <video
                    className={styles.video}
                    ref={videoRef}
                    onTimeUpdate={handleProgress}
                >
                    <source src="https://res.cloudinary.com/codelife/video/upload/v1637805738/intro_l5ul1k.mp4"
                            type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <div className={styles.controls}>
                    <div className={styles.buttons}>
                        <button onClick={handlePlayPause}><PlayPauseButton/></button>
                    </div>
                    <div className={styles.progressBox}>
                        <input
                            type="range"
                            ref={progressRef}
                            className={styles.progress}
                            onChange={handleProgressChange}
                        />
                    </div>
                    <div className={styles.time}>
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VideoPlayer;
