// api-config.js
const apiConfig = {
    paths: {
        auth: '/auth',
        dashboard: '/dashboard',
        settings: '/settings'
    },
    methods: {
        get: 'GET',
        post: 'POST'
    }
};

export default apiConfig;
