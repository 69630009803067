import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import DailyCheckinPages from "../../DailyCheckinPages/DailyCheckinPages";
import L1S1a from "./Section1/L1S1a/L1S1a";
import L1S1b from "./Section1/L1S1b/L1S1b";
import L1S2a from "./Section2/L1S2a/L1S2a";
import UnAuthorizedPage from "../../../Public/UnAuthorizedPage/UnAuthorizedPage";
import Introductions from "../Lessons/components/Introductions/Introductions";
import {getData, setData} from "../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../services/data/constants";
import AccessForbidden from "../../components/AccessForbidden/AccessForbidden";
import L1S3a from "./Section3/L1S3a/L1S3a";
import L1S4a from "./Section4/L1S4a/L1S4a";
import L1S5a from "./Section5/L1S5a/L1S5a";
import L1S4b from "./Section4/L1S4b/L1S4b";
import Homework from "./Homework/Homework";

const Lesson1 = () => {
    const navigate = useNavigate();
    const mapping = ["intro", "a", "b", "c"]
    const lessonNum = 1;
    const lesson = `lesson${lessonNum}`
    const completedCheckin = Object.keys(getData(CATEGORIES.CHECKIN))[lessonNum - 1] === `lesson${lessonNum}`;
    const [currentPage, setCurrentPage] = useState({
        section: getData(CATEGORIES.LESSONS)[lesson]?.currentPage.section ?? 1,
        page: getData(CATEGORIES.LESSONS)[lesson]?.currentPage.page ?? 0
    });
    useEffect(() => {
        setData(CATEGORIES.LESSONS, {
            [lesson]: {
                currentPage: currentPage
            }
        }, true);

    }, [currentPage, navigate]);

    const allPages = [<Introductions thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S1a thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S1b thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S2a thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S3a thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S4a thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S4b thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <L1S5a thisPage={setCurrentPage} thisLesson={lessonNum}/>,
        <Homework thisPage={setCurrentPage} thisLesson={lessonNum}/>,
    ]

    useEffect(() => {
        const lessonData = getData(CATEGORIES.LESSONS);
        lessonData[lesson] = lessonData[lesson] || {};
        lessonData[lesson].totalPages = allPages.length - 1;
        lessonData[lesson].pages = lessonData[lesson].pages || {};
    }, []);


    const checkCompletion = (section, page) => {
        return getData(CATEGORIES.LESSONS)[lesson]?.pages[`page${section}/${page}`]?.isComplete ?? false;
    };

    return (
        <Routes>
            {completedCheckin ? (
                    <>
                        <Route path="checkin" element={<UnAuthorizedPage/>}/>
                        <Route path="section/1">
                            <Route path="intro" element={allPages[0]}/>
                            <Route path="a" element={checkCompletion(1, 0) ?
                                allPages[1] : <AccessForbidden/>}/>
                            <Route path="b" element={checkCompletion(1, 1) ?
                                allPages[2] : <AccessForbidden/>}/>
                        </Route>
                        <Route path="section/2">
                            <Route path="a" element={checkCompletion(1, 2) ?
                                allPages[3] : <AccessForbidden/>}/>
                        </Route>
                        <Route path="section/3">
                            <Route path="a" element={checkCompletion(2, 1) ?
                                allPages[4] : <AccessForbidden/>}/>
                        </Route>
                        <Route path="section/4">
                            <Route path="a" element={checkCompletion(3, 1) ?
                                allPages[5] : <AccessForbidden/>}/>
                            <Route path="b" element={checkCompletion(4, 1) ?
                                allPages[6] : <AccessForbidden/>}/>
                        </Route>
                        <Route path="section/5">
                            <Route path="a" element={checkCompletion(4, 2) ?
                                allPages[7] : <AccessForbidden/>}/>
                        </Route>
                        <Route path="section/6">
                            <Route path="a" element={checkCompletion(4, 2) ?
                                allPages[8] : <AccessForbidden/>}/>
                        </Route>


                        <Route path="*" element={<Navigate
                            to={`section/${currentPage.section ?? '1'}/${mapping[currentPage.page] ?? 'intro'}`}
                            replace/>
                        }/>
                    </>
                ) :
                (
                    <>
                        <Route path="*" element={<Navigate to="./checkin" replace/>}/>
                        <Route index={true} path="checkin" element={<DailyCheckinPages lesson={lessonNum}/>}/>
                    </>
                )
            }
        </Routes>
    );
};

export default Lesson1;