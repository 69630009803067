import React, {useState} from 'react';
import styles from './CheckinPage3.module.scss';
import parent from "../../DailyCheckinPages.module.scss";

const CheckinPage3 = ({onPageChange, onFormSubmit, currentData}) => {
    const [formValues, setFormValues] = useState({});
    const [pageNum] = useState(3)

    const pageState = state => {
        let page = pageNum;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = (event, state) => {
        event.preventDefault();
        onFormSubmit({[`page${pageNum}`]: formValues});
        pageState(state);
    };

    const checkboxes = [
        'I got hit, kicked, or pushed',
        'I was insulted, made fun of, or called a bad name',
        'I got left out or excluded from the group',
        'I was talked about behind my back',
        'A mean or embarrassing text or post was made about me',
        'I had something taken from me or broken',
        'None of these things above happened to me'
    ];


    return (
        <div className={styles.CheckinPage2}>
            <div className={parent.margin}>
                <div className={parent.title}>
                    <h5>Did any of these things happen to YOU yesterday?</h5>
                </div>
                <div className={parent.required}>
                    <div className={"caption-text"}>Check ALL that apply</div>
                </div>
                <form onSubmit={(e) => handleSubmit(e, true)}>
                    <div className={parent.checkboxWrapper}>
                        {checkboxes.map((text, index) => {
                            const id = `option${index + 1}`;
                            return (
                                <label className={parent.container} htmlFor={id} key={id}>
                                    <input
                                        className={parent.check}
                                        type="checkbox"
                                        id={id}
                                        name={id}
                                        value={text}
                                        onChange={handleChange}
                                        defaultChecked={currentData[`page${2}`]?.[id] === text}
                                    />
                                    <span className={parent.checkmark}></span>
                                    {text}
                                </label>
                            );
                        })}
                    </div>
                    <div className={parent.buttonGroup}>
                        <button type={"button"} onClick={(e) => handleSubmit(e, false)}
                                className={parent.backButton}>Back
                        </button>
                        <button type={"submit"} className={parent.nextButton}>Next</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckinPage3;
