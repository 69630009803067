import React from 'react';
import styles from './HomeBody2.module.scss';
import homeStyles from '../HomePage.module.scss';
import {Typography} from "@mui/material";
import triangle from "../../../../assets/HomePage/circle.png";


const HomeBody2 = () => (
    <div className={`${styles.HomeBody2} ${homeStyles.wrapper}`}>
        <div className={"margin"}>
            <div className={`${styles.content} ${homeStyles.content}`}>
                <div className={`${styles.text} ${homeStyles.text}`}>
                    <div className={styles.textContainer}>
                        <div className={styles.titleText}><Typography variant="h2">Heading 2</Typography></div>
                        <div className={styles.subText}>
                            <Typography variant="h5">
                                How promotion excellent curiosity yet attempted happiness. Gay prosperous impression had
                                conviction. For every delay death ask style. Me mean able my by in they. Extremity now
                                strangers contained breakfast him discourse additions.
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={`${styles.box} ${homeStyles.box}`}>
                    <img src={triangle} alt={"Triangle image"}/>
                </div>

            </div>
        </div>
    </div>
);

export default HomeBody2;
