// index.js
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {Amplify} from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);