import React, {useState} from 'react';
import styles from './CheckinPage1.module.scss';
import parent from '../../DailyCheckinPages.module.scss';

const CheckinPage1 = ({onPageChange, onFormSubmit, currentData}) => {
    const [formValues, setFormValues] = useState({});
    const [pageNum] = useState(1)

    const pageState = state => {
        let page = pageNum;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = (event, state) => {
        event.preventDefault();
        onFormSubmit({[`page${pageNum}`]: formValues});
        pageState(state);
    };

    const emotions = ['Happy', 'Sad', 'Nervous', 'Excited', 'Hopeful', 'Depressed', 'Mad', 'Ashamed', 'Confident', 'Embarrassed', 'Calm', 'Proud'];

    return (
        <div className={styles.CheckinPage1}>
            <div className={parent.margin}>
                <div className={parent.title}>
                    <h5>How are you feeling right now?</h5>
                </div>
                <div className={parent.required}>
                    <div className="caption-text">All fields are required</div>
                </div>
                <form onSubmit={(e) => handleSubmit(e, true)}>
                    {emotions.map((emotion) => (
                        <div key={emotion}>
                            <h6>{emotion}</h6>
                            <div className={`${parent.radioGroup} ${styles.group}`}>
                                {['Yes', 'No', 'Maybe'].map((option) => (
                                    <label key={option} className={parent.container}>
                                        <div className={parent.radioText}>{option}</div>
                                        <input
                                            className={parent.radio}
                                            type="radio"
                                            name={emotion}
                                            value={option.toLowerCase()}
                                            onChange={handleChange}
                                            defaultChecked={currentData[`page${pageNum}`]?.[emotion] === option.toLowerCase()}
                                            // required={true}
                                        />
                                        <span className={parent.checkmark}></span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className={parent.buttonGroup}>
                        <button type="submit" className={parent.nextButton}>Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckinPage1;
