import React, {useEffect} from 'react';
import styles from './Introductions.module.scss';
import parent from '../../Lessons.module.scss';
import {Link, useLocation} from "react-router-dom";
import {getIntroContent} from "./introContent";
import {useColor} from "../../../../ColorTheme/ThemeContext";
import {getData, setData} from "../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../services/data/constants";

const Introductions = ({thisLesson, thisPage}) => {
    let {colors} = useColor();
    const getImagePath = (thisLesson) => {
        return require(`../../../../../../assets/course/Lesson${thisLesson}/Introductions/photo.png`);
    };

    const lesson = `lesson${thisLesson}`;
    const pageID = {
        section: 1,
        page: 0
    };
    useEffect(() => {
        thisPage(pageID);
    }, []);

    const handleCompletion = () => {
        const completion = getData(CATEGORIES.LESSONS)
        completion[lesson].pages[`page${pageID.section}/${pageID.page}`] = {isComplete: true}
    }


    const para = getIntroContent()[thisLesson - 1]
    return (
        <div className={styles.Introductions}>
            <div className={styles.rows}>
                <div className={styles.graphic}>
                    <div style={{border: `dashed 5px rgba(${colors.accent}, .2)`}} className={styles.circle}>
                        <div style={{backgroundColor: `rgba(${colors.primary}, .2)`}}
                             className={styles.container}>
                            <img className={styles.img} src={getImagePath(thisLesson)}
                                 alt={"Lesson Graphic"}/>
                        </div>
                    </div>
                </div>
                <div className={styles.text}>
                    <h2 style={{color: `rgba(${colors.accent}, 1)`}}>Lesson {thisLesson}</h2>
                    <p>{para}</p>
                </div>
            </div>
            <div className={`${parent.buttonGroupRight} ${parent.buttonGroup}`}>
                <Link to={"../a"} as={"button"}>
                    <button
                        onClick={handleCompletion}
                        className={`${parent.nextButton} button-ok-course`}>Next
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Introductions;