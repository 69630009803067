import React, {useEffect, useState} from 'react';
import styles from './DailyCheckinPages.module.scss';
import CheckinWelcome from "./pages/CheckinWelcome/CheckinWelcome";
import {useColor} from "../ColorTheme/ThemeContext";
import CheckinPage1 from "./pages/CheckinPage1/CheckinPage1";
import CheckinHead from "./pages/components/CheckinHead/CheckinHead";
import CheckinPage2 from "./pages/CheckinPage2/CheckinPage2";
import CheckinPage3 from "./pages/CheckinPage3/CheckinPage3";
import CheckinMessage from "./pages/CheckinMessage/CheckinMessage";
import CheckinPage5 from "./pages/CheckinPage5/CheckinPage5";
import CheckinPage6 from "./pages/CheckinPage6/CheckinPage6";
import {getData, setData} from "../../../services/data/dataManager";
import {CATEGORIES} from "../../../services/data/constants";
import checkinService from "../../../services/API_Resources/curriculum/checkin-service";
import {useNavigate} from "react-router-dom";

const DailyCheckinPages = ({lesson}) => {
    const {colors} = useColor();
    const [formDataStore, setFormDataStore] = useState({});
    const [formCurrentData, setFormCurrentData] = useState({});
    const navigate = useNavigate();
    const processForm = (formData) => {
        setFormDataStore(prevStore => {
            const pageKey = Object.keys(formData)[0];
            const newFormValues = formData[pageKey];
            const existingFormValues = prevStore[pageKey] || {};
            const hasChanged = Object.keys(newFormValues).some(key => newFormValues[key] !== existingFormValues[key]);
            if (!hasChanged) {
                // console.log('No changes detected');
                return prevStore;
            }
            const updatedPageData = {...existingFormValues, ...newFormValues};
            const newStore = {...prevStore, [pageKey]: updatedPageData};
            // console.log('Data updated:', newStore);
            setFormCurrentData(newStore);
            return newStore;
        });
    };


    const [currentPage, setCurrentPage] = useState(0);
    const allPages = [<CheckinWelcome number={lesson} onPageChange={setCurrentPage}/>,
        <CheckinPage1 currentData={formCurrentData} onFormSubmit={processForm} onPageChange={setCurrentPage}/>,
        <CheckinPage2 currentData={formCurrentData} onFormSubmit={processForm} onPageChange={setCurrentPage}/>,
        <CheckinPage3 currentData={formCurrentData} onFormSubmit={processForm} onPageChange={setCurrentPage}/>,
        <CheckinMessage currentData={formCurrentData} onPageChange={setCurrentPage}/>,
        <CheckinPage5 currentData={formCurrentData} onFormSubmit={processForm} onPageChange={setCurrentPage}/>,
        <CheckinPage6 currentData={formCurrentData} onFormSubmit={processForm} onPageChange={setCurrentPage}/>]

    const renderPage = () => allPages[currentPage];
    const currentProgress = currentPage / (allPages.length - 1);

    useEffect(() => {
        const submitData = async () => {
            if (currentPage !== allPages.length) return;

            try {
                const allFormData = {[`lesson${lesson}`]: formDataStore};
                const response = await checkinService(allFormData);
                response ? navigate('section') : alert('Something went wrong');
            } catch (error) {
                console.error('Error submitting form data:', error);
            }
        };

        submitData();
    }, [currentPage]);


    useEffect(() => {
        const lessonsElement = document.querySelector(`.${styles.wrapper}`);

        if (lessonsElement) {
            lessonsElement.querySelectorAll(`.${styles.nextButton}`).forEach(element => {
                element.style.backgroundColor = `rgba(${colors.primary}, 1)`;
            });
            lessonsElement.querySelectorAll(`.${styles.title}`).forEach(element => {
                element.style.color = `rgba(${colors.primary}, 1)`;
            });
        }
    }, [colors, currentPage]);


    return (
        <div className={styles.DailyCheckinPages} style={{color: `rgba(${colors.accent}, 1)`}}>
            <div className={styles.wrapper}>
                {currentPage !== 0 && currentPage !== 4 ?
                    <CheckinHead progress={currentProgress} lessonNum={lesson} pageNum={currentPage}/> : null}
                {renderPage()}
            </div>
        </div>
    );
};

export default DailyCheckinPages;
