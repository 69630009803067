import React, {useEffect} from 'react';
import styles from './CircleBackground.module.scss';
import {useColor} from "../../../../ColorTheme/ThemeContext";

const CircleBackground = () => {
    const {colors} = useColor();
    useEffect(() => {
        document.querySelectorAll(`.${styles.circles}`).forEach(element => {
            element.style.backgroundColor = `rgba(${colors.primary}, 1)`;
        });

    }, [colors]);
    return (
        <div className={styles.CircleBackground}>
            <div className={styles.circles} id={styles.circle1}/>
            <div className={styles.circles} id={styles.circle2}/>
            <div className={styles.circles} id={styles.circle3}/>
        </div>
    );
};


export default CircleBackground;
