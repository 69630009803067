import React from 'react';
import styles from './BackgroundAnimation1.module.scss'

const BackgroundAnimation1 = () => {
    return (
        <div className={styles.BackgroundAnimation1}>
            <ul className={styles.circles}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    )
}

export default BackgroundAnimation1;