import React, {useEffect} from 'react';
import styles from './L1S3a.module.scss';
import parent from "../../../Lessons/Lessons.module.scss";
import {Link} from "react-router-dom";
import {getData} from "../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../services/data/constants";
import {Typography} from "@mui/material";
import PictureBox from "./PictureBox/PictureBox";

const L1S3a = ({thisPage, thisLesson}) => {
    const lesson = `lesson${thisLesson}`;
    const pageID = {
        section: 3,
        page: 1
    };
    useEffect(() => {
        thisPage(pageID);
    }, []);

    const handleCompletion = () => {
        const completion = getData(CATEGORIES.LESSONS)
        completion[lesson].pages[`page${pageID.section}/${pageID.page}`] = {isComplete: true}
    }

    return (
        <div className={styles.L1S2a}>
            <div className={parent.content}>
                <div className={parent.group}>
                    <Typography className={"title-course"} variant="h2">Instructional Activity</Typography>
                    <p className={parent.wrapped}>On could my in order never it. Or excited certain sixteen it to
                        parties
                        colonel. Depending
                        conveying
                        direction has led immediate. Law gate her well bed life feet seen rent. On nature or no except
                        it
                        sussex.</p>
                </div>
                <div className={"divider"}></div>
            </div>
            <div className={parent.columns}>
                <div className={styles.box}><PictureBox number={1}/></div>
                <div className={styles.box}><PictureBox number={2}/></div>
                <div className={styles.box}><PictureBox number={3}/></div>

            </div>
            <div className={parent.content}>
                <div className={parent.group}>
                    <Typography className={"title-course"} variant="h4">Conclusion</Typography>
                    <p className={parent.wrapped}>On could my in order never it. Or excited certain sixteen it to
                        parties
                        colonel. Depending
                        conveying
                        direction has led immediate. Law gate her well bed life feet seen rent. On nature or no except
                        it
                        sussex.</p>
                </div>
            </div>
            <div className={`${parent.buttonGroupRight} ${parent.buttonGroup}`}>
                <Link to={"../../section/2/a"} as={"button"}>
                    <button className={`${parent.backButton}`}>Back</button>
                </Link>
                <Link to={"../../section/4/a"} as={"button"}>
                    <button
                        onClick={handleCompletion}
                        className={`${parent.nextButton} button-ok-course`}>Next
                    </button>
                </Link>
            </div>
        </div>
    );
}


export default L1S3a;
