import React from 'react';
import styles from './CheckinWelcome.module.scss'
import parent from '../../DailyCheckinPages.module.scss'
import {useColor} from "../../../ColorTheme/ThemeContext";
import {Typography} from "@mui/material";

const CheckinWelcome = ({number, onPageChange}) => {
    const {colors} = useColor();

    const pageState = state => {
        let page = 0;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };


    return (
        <div className={styles.CheckinWelcome} style={{backgroundColor: `rgba(${colors.accent}, .1)`}}>
            <div className={parent.centerMiddle}>
                <div>
                    <div className={styles.head}>
                        <Typography variant="h4">Please complete the Lesson {number} daily checkin before moving
                            on.</Typography>
                    </div>
                    <div className={styles.para}>
                        <Typography variant="h6">This is where you can drought down your feelings to see where you are
                            at
                            before each lesson.</Typography>
                    </div>

                    <div className={styles.para}>
                        <h6>This is where you can drought down your feelings to see where you are at
                            before each lesson.</h6>
                    </div>
                    <div className={parent.letsGo} style={{color: `rgba(${colors.primary}, 1)`}}
                         onClick={() => pageState(true)}
                    >
                        <h4>Ok, Lets Go</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckinWelcome;