import colorZone from "./colorsList";

function getColors(pallet) {
    const defaultPallet = 1;
    const colorKey = `color${pallet}`;
    const selectedPallet = colorZone[colorKey] || colorZone[`color${defaultPallet}`];

    return {
        primary: selectedPallet.primary,
        accent: selectedPallet.accent
    };
}

export default getColors;
