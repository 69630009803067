import React, {useEffect} from 'react';
import styles from './L1S1a.module.scss';
import parent from "../../../Lessons/Lessons.module.scss";
import {Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {getData} from "../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../services/data/constants";

const L1S1a = ({thisPage, thisLesson}) => {
    const lesson = `lesson${thisLesson}`;
    const pageID = {
        section: 1,
        page: 1
    };
    useEffect(() => {
        thisPage(pageID);
    }, []);

    const handleCompletion = () => {
        const completion = getData(CATEGORIES.LESSONS)
        completion[lesson].pages[`page${pageID.section}/${pageID.page}`] = {isComplete: true}
    }
    return (
        <div className={styles.L1S1a}>
            <div className={parent.content}>
                <div className={parent.group}>
                    {/*<h3 className={".title"}>Battery Bucks</h3>*/}
                    <Typography className={"title-course"} variant="h2">Battery Bucks</Typography>
                    <p className={parent.wrapped}>On could my in order never it. Or excited certain sixteen it to
                        parties
                        colonel. Depending
                        conveying
                        direction has led immediate. Law gate her well bed life feet seen rent. On nature or no except
                        it
                        sussex.</p>
                </div>
                <div className={`${parent.dividerSection} divider-section-course`}></div>
                <div className={parent.group}>
                    <div className={parent.rows}>
                        <div>
                            <div className={parent.columns}>
                                <div className={styles.imgHolder}>

                                </div>
                                <div className={parent.columns}>
                                    <p className="subtitle2">Responding</p>
                                    <p className="caption-text">Commanded no of depending extremity recommend attention
                                        tolerably. Five with seen put need tore add neat. Bringing it is he returned
                                        received raptures.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.imgHolder}>

                            </div>
                            <div className={parent.columns}>
                                <p className="subtitle2">Completing</p>
                                <p className="caption-text">Reasonably how possession shy way introduced age inquietude.
                                    Missed
                                    he engage no clue of. Still tried means we </p>
                            </div>
                        </div>
                        <div>
                            <div className={styles.imgHolder}>

                            </div>
                            <div className={parent.columns}>
                                <p className="subtitle2">Engaging</p>
                                <p className="caption-text">So before remark at depart. Did son unreserved themselves
                                    indulgence its.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${parent.dividerSection} divider-section-course`}></div>
                <div className={parent.group}>
                    <h4 className={"title-course"}>Why should I earn them?</h4>
                    <p className={parent.wrapped}>
                        Their could can widen ten she any. As so we smart those money in. Am wrote up whole so tears
                        sense
                        oh. Absolute required of reserved in offering no. How sense found our those gay again taken the.
                    </p>
                </div>
            </div>
            <div className={`${parent.buttonGroupRight} ${parent.buttonGroup}`}>
                <Link to={"../intro"} as={"button"}>
                    <button className={`${parent.backButton}`}>Back</button>
                </Link>
                <Link to={"../b"} as={"button"}>
                    <button
                        onClick={handleCompletion}
                        className={`${parent.nextButton} button-ok-course`}>Next
                    </button>
                </Link>
            </div>

        </div>
    );
}

export default L1S1a;
