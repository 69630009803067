import React from 'react';
import styles from './PersonalSection.module.scss';
import ModelSectionLabel from "../../../components/ModalSectionLabel/ModalSectionLabel";

const PersonalSection = () => (
    <div className={styles.PersonalSection}>
        <ModelSectionLabel name="Personal Info"/>
    </div>
);


export default PersonalSection;
