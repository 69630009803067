import React, {useEffect, useState, useRef} from 'react';
import styles from './DashboardPage.module.scss';
import HeadPanel from './HeadPanel/HeadPanel';
import SidePanel from './SidePanel/SidePanel';
import MainWindow from './MainWindow/MainWindow';
import {useColor} from "../ColorTheme/ThemeContext";
import settingsService from "../../../services/API_Resources/curriculum/settings-service";
import apiConfig from "../../../services/API_Resources/api-config";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import ModalBox from "./components/modals/ModalBox/ModalBox";
import {getData} from "../../../services/data/dataManager";
import {CATEGORIES} from "../../../services/data/constants";

const DashboardPage = () => {
    const {colors} = useColor();
    const {changePalette} = useColor();
    const [resize, setResize] = useState(false);
    const [modal, setModal] = useState(null);
    const [error, setError] = useState(null);
    const backgroundRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const toggleResize = () => setResize(prevResize => !prevResize);

    const [modalContent, setModalContent] = useState(null);

    const handleCallModal = (modalType) => {
        setModalContent(modalType);
    };

    const resetMode = () => {
        setModalContent(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await settingsService(apiConfig.methods.get);
                const colorTheme = getData(CATEGORIES.SETTINGS).colorTheme ?? -1
                changePalette(colorTheme);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.DashboardPage}>
            <div className={styles.background} style={{backgroundColor: `rgba(${colors.primary}, .1)`}}>
                <HeadPanel resizeHandler={toggleResize}/>
                <LoadingBar isLoading={isLoading}/>
                <div className={styles.body}>
                    <SidePanel resizeState={resize} onCallModal={handleCallModal}/>
                    <MainWindow resizeState={resize}/>
                </div>
            </div>
            <ModalBox mode={modalContent} onResetMode={resetMode}/>
        </div>
    );
};

export default DashboardPage;
