import {useNavigate} from 'react-router-dom';
import Lesson1 from "../../../pages/Private/course/Lesson1/Lesson1";
import {setData} from "../../data/dataManager";
import {CATEGORIES} from "../../data/constants";

const checkinService = async (formData) => {
    // This will need to send the data to AWS to save to DB

    return !!validation(formData);
};

const validation = (data) => {
    // for (const lessonKey in lesson) {
    //     const pages = lesson[lessonKey];
    //     for (const page in pages) {
    //         if (Object.keys(pages[page]).length === 0) {
    //             return false;
    //         }
    //     }
    // }
    // return true;
    setData(CATEGORIES.CHECKIN, data)
    return !!data;
};

export default checkinService;
