import React from 'react';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => (
    <div className={styles.NotFoundPage}>
        NotFoundPage Component
    </div>
);


export default NotFoundPage;
