import React from 'react';
import styles from './HomePage.module.scss';
import people from '../../../assets/HomePage/people.png'
import HomeHead from "./HomeHead/HomeHead";
import HomeBody1 from "./HomeBody1/HomeBody1";
import HomeBody2 from "./HomeBody2/HomeBody2";

const HomePage = () => (
    <div className={styles.HomePage}>
        <HomeHead/>
        <HomeBody1/>
        <HomeBody2/>
    </div>
);

export default HomePage;
