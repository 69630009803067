import MainApiService from "../main-api-service";
import {getData, setData} from "../../data/dataManager";
import apiConfig from "../api-config";
import {CATEGORIES} from "../../data/constants";
import {useColor} from "../../../pages/Private/ColorTheme/ThemeContext";

async function settingsService(method) {
    let body;
    let queryParams;
    switch (method) {
        case apiConfig.methods.get:
            body = null;
            queryParams = {pointer: getData(CATEGORIES.SESSION).studentObjectID}
            break;
        case apiConfig.methods.post:
            body = getData(CATEGORIES.SESSION).studentObjectID
            queryParams = {}
            break;
        default:
            body = null
    }
    try {
        const rawResponse = await MainApiService(method, apiConfig.paths.settings, undefined, queryParams, body);
        const parsedResponse = await rawResponse.body.json();
        setData(CATEGORIES.SETTINGS, parsedResponse, true);
        return parsedResponse;
    } catch (error) {
        console.error(`Application layer error - Dashboard Service: ${error}`);
        throw error;
    }
}

export default settingsService;
