import React, {useEffect} from 'react';
import styles from './LessonsSection.module.scss';
import {Label} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useColor} from "../../../ColorTheme/ThemeContext";
import {getData} from "../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../services/data/constants";

const LessonsSection = () => {
    const {colors} = useColor();
    useEffect(() => {
        document.querySelectorAll(`.${styles.whole}`).forEach(element => {
            element.style.backgroundColor = `rgba(${colors.primary}, .5)`;
        });
        document.querySelectorAll(`.${styles.launchButton}`).forEach(element => {
            element.style.backgroundColor = `rgba(${colors.accent}, 1)`;
        });
        document.querySelectorAll(`.${styles.percent}`).forEach(element => {
            element.style.backgroundColor = `rgba(${colors.primary}, 1)`;
        });
        document.querySelectorAll(`.${styles.main}`).forEach(element => {
            element.style.color = `rgba(${colors.accent}, 1)`;
        });
    }, [colors]);

    const singleLessonProgress = (lessonNum) => {
        const lessonData = getData(CATEGORIES.LESSONS)[`lesson${lessonNum}`] || {};
        const pages = lessonData.pages || {};
        const numerator = Object.keys(pages).length;
        const denominator = lessonData.totalPages || 0;

        return denominator ? (numerator / denominator) * 100 : 0;
    }


    return (
        <div className={styles.LessonsSection}>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 1</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent} style={{width: `${singleLessonProgress(1)}%`}}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 2</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/2/section/1/a">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 3</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 4</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 5</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 6</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 7</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
            <div className={styles.lesson} tabIndex="0">
                <div className={styles.main}>
                    <div className={styles.label}>
                        <h6>Lesson 8</h6>
                    </div>
                    <div className={styles.progressBar}>
                        <div className={styles.whole}>
                            <div className={styles.percent}></div>
                        </div>
                    </div>
                    <div className={styles.launch}>
                        <Link to="./lesson/1">
                            <button className={styles.launchButton}>Launch</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.more}>
                    This is more
                </div>
            </div>
        </div>
    );
};

export default LessonsSection;
