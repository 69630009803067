import {Route, Routes, useLocation} from "react-router-dom";
import Lesson1 from "../Lesson1/Lesson1";
import Lesson2 from "../Lesson2/Lesson2";
import {useColor} from "../../ColorTheme/ThemeContext";
import styles from "./Lessons.module.scss";
import {useEffect} from "react";


const Lessons = () => {
    const {colors} = useColor();
    const location = useLocation();

    useEffect(() => {
        const lessonsElement = document.querySelector(`.${styles.Lessons}`);
        // console.log(lessonsElement)
        if (lessonsElement) {
            lessonsElement.querySelectorAll(".title-course").forEach(element => {
                element.style.color = `rgba(${colors.accent}, 1)`;
            });
            lessonsElement.querySelectorAll(".button-ok-course").forEach(element => {
                element.style.backgroundColor = `rgba(${colors.primary}, 1)`;
            });
            lessonsElement.querySelectorAll(".subtitle2").forEach(element => {
                element.style.color = `rgba(${colors.accent}, 1)`;
            });
            lessonsElement.querySelectorAll(".divider-section-course").forEach(element => {
                element.style.backgroundColor = `rgba(${colors.primary}, 1)`;
            });
        }
    }, [colors, location]);


    return (
        <div className={styles.Lessons}>
            <Routes>
                {/*<Route index element={<Navigate to={'1'}/>}/>*/}
                <Route index path="1/*" element={<Lesson1/>}/>
                <Route path="2/*" element={<Lesson2/>}/>
            </Routes>
        </div>
    );
};

export default Lessons;
