import React from 'react';
import styles from './SettingsModal.module.scss';
import ColorThemeSection from "./sections/ColorThemeSection/ColorThemeSection";

const SettingsModal = () => (
    <div className={styles.SettingsModal}>
        <ColorThemeSection/>
    </div>
);


export default SettingsModal;
