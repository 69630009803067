import React from 'react';
import styles from './PublicHeader.module.scss';
import {Typography} from "@mui/material";

const PublicHeader = ({pageName}) => (
    <div className={styles.PublicHeader}>
        <div>
            <Typography variant="h3">
                {pageName}
            </Typography>
        </div>
    </div>
);


export default PublicHeader;
