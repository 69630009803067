import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {ThemeProvider} from "./ColorTheme/ThemeContext";
import {getData} from "../../services/data/dataManager";
import {CATEGORIES} from "../../services/data/constants";

const PrivateLayout = () => {
    const isAuthenticated = getData(CATEGORIES.SESSION).authenticated;
    //console.log(isAuthenticated)
    if (!isAuthenticated) {
        return <Navigate to="/login"/>;
    }

    return (
        <ThemeProvider>
            <div><Outlet/></div>
        </ThemeProvider>
    );
};

export default PrivateLayout;
