import React from 'react';
import styles from './CheckinHead.module.scss';
import parent from '../../../DailyCheckinPages.module.scss'
import global from '../../../../../../services/global'
import {useColor} from "../../../../ColorTheme/ThemeContext";

const CheckinHead = ({lessonNum, progress, pageNum}) => {
    const {colors} = useColor();
    const percent = `${progress * 100}%`

    return (
        <div className={styles.CheckinHead}>
            <div className={styles.wrapper} style={{backgroundColor: `rgba(${colors.accent}, .2)`}}>
                <div className={parent.margin}>
                    <div className={styles.main}>
                        <h6>Lesson {lessonNum} Checkin: Page {pageNum}</h6>
                        <p>{global.currentDate}</p>
                    </div>
                </div>
                <div className={styles.total} style={{backgroundColor: `rgba(${colors.accent}, .4)`}}>
                    <div className={styles.index} style={{
                        backgroundColor: `rgba(${colors.accent}, .5)`,
                        width: percent
                    }}></div>
                </div>
            </div>

        </div>
    )
}

export default CheckinHead;