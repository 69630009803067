import MainApiService from "../main-api-service";
import Global from "../../global";
import {getData, setData} from "../../data/dataManager";
import {CATEGORIES} from "../../data/constants";
import apiConfig from "../api-config";


async function signInService(username, password) {
    const body = {
        username: username,
        password: password
    };
    const pathParams = "login"

    try {
        const rawResponse = await MainApiService('POST', apiConfig.paths.auth, pathParams, undefined, body);
        const parsedResponse = await rawResponse.body.json();
        if (parsedResponse.pointer) {
            setData(CATEGORIES.SESSION, {
                authenticated: true,
                studentObjectID: parsedResponse.pointer
            }, true);
        } else {
            setData(CATEGORIES.SESSION, {authenticated: false})
        }
        console.log('Session data:', getData(CATEGORIES.SESSION));
        return parsedResponse.pointer


    } catch (error) {
        console.error(`Application layer 2 error - Sign In: ${error}`);

    }


}

export default signInService;
