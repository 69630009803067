// ThemeContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import getColors from "./colorThemeHandler";
import {getData} from "../../../services/data/dataManager";
import {CATEGORIES} from "../../../services/data/constants";


const ThemeContext = createContext();

export const useColor = () => useContext(ThemeContext);

export const ThemeProvider = ({children}) => {
    const [palette, setPalette] = useState(0); // Initial palette

    const changePalette = (newPalette) => {
        setPalette(newPalette);
    };
    const colors = getColors(palette);

    return (
        <ThemeContext.Provider value={{palette, changePalette, colors}}>
            {children}
        </ThemeContext.Provider>
    );
};
