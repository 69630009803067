import MainApiService from "../main-api-service";
import Global from "../../global";
import {getData, setData} from "../../data/dataManager";
import apiConfig from "../api-config";

async function dashboardService() {
    const body = {type: "dashboard"}
    try {
        const rawResponse = await MainApiService('GET', apiConfig.paths.dashboard, undefined, undefined, body);
        const parsedResponse = await rawResponse.body.json();
        setData('dashboard', parsedResponse);
        return parsedResponse;
    } catch (error) {
        console.error(`Application layer error - Dashboard Service: ${error}`);
        throw error;
    }
}

export default dashboardService;
