// App.js
import React from 'react';
import {BrowserRouter as Router, Routes, Route, useRoutes} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material";
import mui from './theme.modules.scss';
import styles from './App.scss'
import routes from './pages/Routes';

const theme = createTheme({
    palette: {
        primary: {
            main: mui.colorPrimary
        },
        secondary: {
            main: mui.colorSecondary
        },
        error: {
            main: mui.colorDanger
        }
    },
    typography: {
        fontFamily: "'Inter Tight', sans-serif",
    }
})

function RouterComponent() {
    const routing = useRoutes(routes);
    return (
        <div className="App">
            {routing}
        </div>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className={styles.App}>
                <Router>
                    <RouterComponent/>
                </Router>
            </div>
        </ThemeProvider>
    )
        ;
}

export default App;

