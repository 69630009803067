import React, {useEffect} from 'react';
import styles from './OverviewSection.module.scss';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CelebrationIcon from '@mui/icons-material/Celebration';
import {Typography} from "@mui/material";
import {useColor} from "../../../ColorTheme/ThemeContext";

const OverviewSection = () => {
    const {colors} = useColor();
    useEffect(() => {
        document.querySelectorAll(`.${styles.item}`).forEach(element => {
            element.style.backgroundColor = `rgba(${colors.primary}, .5)`;
        });
        document.querySelectorAll(`.${styles.overview}`).forEach(element => {
            element.style.color = `rgba(${colors.accent}, 1)`;
        });
        document.querySelector(`.${styles.overview}`).style.borderBottom = `solid 5px rgba(${colors.accent}, 1})`
    }, [colors.primary, colors.accent]);


    return (
        <div className={styles.OverviewSection}>
            <h6 className={styles.overview}>Overview</h6>
            <div className={styles.section}>
                <div id={styles.item1} className={styles.item}>
                    <div className={styles.graphic}>
                        <DonutSmallIcon/>
                    </div>
                    <div className={styles.area}>
                    <span>
                       <div className={styles.number}>23%</div>
                        <div className={styles.subText}>Progress</div>
                    </span>
                    </div>
                </div>
                <div id={styles.item2} className={styles.item}>
                    <div className={styles.graphic}>
                        <BatteryCharging80Icon/>
                    </div>
                    <div className={styles.area}>
                    <span>
                        <div className={styles.number}>15</div>
                        <div className={styles.subText}>Battery Bucks</div>
                    </span>

                    </div>
                </div>
                <div id={styles.item3} className={styles.item}>
                    <div className={styles.graphic}>
                        <ChecklistIcon/>
                    </div>
                    <div className={styles.area}>
                    <span>
                        <div className={styles.number}>2</div>
                        <div className={styles.subText}>Daily Streak</div>
                    </span>
                    </div>
                </div>
                <div id={styles.item4} className={styles.item}>
                    <div className={styles.graphic}>
                        <CelebrationIcon/>
                    </div>
                    <div className={styles.area}>
                    <span>
                        <div className={styles.number}>9</div>
                    <div className={styles.subText}>Lessons Left</div>
                    </span>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default OverviewSection;
