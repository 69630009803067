import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalHead.module.scss';
import {useColor} from "../../../../../ColorTheme/ThemeContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ModalHead = ({name, onBack}) => {
    const {colors} = useColor();
    return (
        <div style={{backgroundColor: `rgba(${colors.accent}, .5)`}} className={styles.ModalHead}>
            <div>
                <button onClick={onBack}>
                    <ArrowBackIcon/>
                </button>
            </div>
            <h3 className={styles.title}>{name}</h3>
        </div>
    );
};

ModalHead.propTypes = {
    name: PropTypes.string,
    onBack: PropTypes.func
};


export default ModalHead;
