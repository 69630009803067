import React from 'react';
import {Outlet} from 'react-router-dom';
import NavBar from "../../components/NavBar/NavBar";

const PublicLayout = () => (
    <>
        <NavBar/>
        <div><Outlet/></div>
    </>
);

export default PublicLayout;
