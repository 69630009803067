import {AppBar, Button, ButtonGroup, IconButton, MenuItem, Toolbar, Typography} from "@mui/material";
import {Link} from 'react-router-dom'; // Import Link from react-router-dom
import styles from './NavBar.module.scss';
import logo from '../../assets/logoMain_128.png'

const NavBar = () => (
    <div className={styles.NavBar}>
        <div className={styles.container}>
            <div className={`${styles.text} ${styles.branding}`}>
                {/*<h3>Powering Up</h3>*/}
                {/*<img src={logo} alt={"logo"}/>*/}

            </div>
            <div className={`${styles.buttonGroup} ${styles.text}`}>
                <Button component={Link} to="/">Home</Button>
                <Button component={Link} to="/about">About</Button>
                <Button component={Link} to="/contact">Contact</Button>
                <Button component={Link} to="/login" className={styles.login}>Login</Button>
            </div>
        </div>
    </div>
);


export default NavBar;
