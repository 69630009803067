import React, {useEffect} from 'react';
import styles from './StudentPageHead.module.scss';
import CircleBackground from "./CircleBackground/CircleBackground";
import imgGraphic from "../../../../../assets/DashboardPage/W_F_Student_On_Computer.png"
import {Typography} from "@mui/material";
import {useColor} from "../../../ColorTheme/ThemeContext";
import {getData} from "../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../services/data/constants";


const StudentPageHead = () => {
    const {colors} = useColor();
    useEffect(() => {
        document.querySelector(`.${styles.StudentPageHead}`).style.backgroundColor = `rgba(${colors.primary}, .4)`;
        document.querySelector(`.${styles.StudentPageHead}`).style.color = `rgba(${colors.accent}, 1)`;
    }, [colors]);

    const name = getData(CATEGORIES.SETTINGS).firstName || "error";
    return (
        <div className={styles.StudentPageHead}>
            <div className={styles.text}>
                {/*<h3 className={styles.welcome}>Hi, {name}</h3>*/}
                <Typography variant={"h3"}>Hi, {name}</Typography>
                <div className={styles.subText}>
                    <p><span id={styles.title}>Inspiration of the day:&nbsp;</span>On abroad danger likely regret twenty
                        edward do. Too horrible consider</p>

                </div>
            </div>
            <div className={styles.graphics}>
                <div className={styles.picture}>
                    {/*<img src={imgGraphic} alt={"Student on a computer"}/>*/}
                </div>
                <CircleBackground/>
            </div>

        </div>
    );
};


export default StudentPageHead;
