import React, {useState} from 'react';
import styles from './CheckinPage5.module.scss'
import parent from "../../DailyCheckinPages.module.scss";

const CheckinPage5 = ({onPageChange, onFormSubmit, currentData}) => {
    const [formValues, setFormValues] = useState({});
    const [pageNum] = useState(5)

    const pageState = state => {
        let page = pageNum;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = (event, state) => {
        event.preventDefault();
        onFormSubmit({[`page${pageNum}`]: formValues});
        pageState(state);
    };
    return (
        <div className={styles.CheckinPage5}>
            <div className={parent.margin}>
                <div className={parent.title}>
                    <h5>Since you filled out page 3, how do you feel when it happened?</h5>
                </div>
                <div className={parent.required}>
                    <div className={"caption-text"}>Fill textbox</div>
                </div>
                <form onSubmit={(e) => handleSubmit(e, true)}>
                    <input
                        className={parent.textarea}
                        name={"textarea"}
                        type={"textarea"}
                        onChange={handleChange}
                        value={formValues.textarea ?? currentData[`page${5}`]?.textarea ?? ''}/>
                    <div className={parent.buttonGroup}>
                        <button type={"button"} className={parent.backButton}>Page 3</button>
                        <button type={"submit"} className={parent.nextButton}>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CheckinPage5

