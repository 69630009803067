import React, {useState} from 'react';
import styles from './MenuButton.module.scss';

const MenuButton = () => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={styles.MenuButton}>
            <svg
                className={`${styles.ham} ${isActive ? styles.hamRotate180 : ''} ${styles.ham5}`}
                viewBox="0 0 50 50" // Adjusted viewBox for smaller size
                width="40" // Adjusted width
                onClick={handleClick}
            >
                <path
                    className={`${styles.line} ${styles.top} ${isActive ? styles.active : ''}`}
                    d="m 15,16 h 20 c 0,0 4.25,-0.34276 4.25,5.1875 0,4.146326 -3.061354,4.501147 -4.25,3.3125 l -5.535714,-5.535714"
                    strokeWidth="3" // Adjusted stroke-width
                />
                <path
                    className={`${styles.line} ${styles.middle}`}
                    d="m 35,25 h -20"
                    strokeWidth="3" // Adjusted stroke-width
                />
                <path
                    className={`${styles.line} ${styles.bottom} ${isActive ? styles.active : ''}`}
                    d="m 15,34 h 20 c 0,0 4.25,0.34276 4.25,-5.1875 0,-4.146326 -3.061354,-4.501147 -4.25,-3.3125 l -5.535714,5.535714"
                    strokeWidth="3" // Adjusted stroke-width
                />
            </svg>
        </div>
    );
};

export default MenuButton;
