import React from 'react'
import styles from './Animation1.module.scss'
import {useColor} from "../../ColorTheme/ThemeContext";
import {useLocation} from "react-router-dom";

const Animation1 = () => {
    const {colors} = useColor();
    const location = useLocation();
    return (
        <div className={styles.Animation1}>
            <div style={{backgroundColor: `rgba(${colors.primary}, .3)`}} className={styles.wrapper}>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
                <div className={styles.cube}></div>
            </div>
        </div>
    )
}

export default Animation1;