import React from 'react';
import styles from './HomeBody1.module.scss';
import homeStyles from '../HomePage.module.scss';
import {Typography} from "@mui/material";
import triangle from "../../../../assets/HomePage/triangle.png";


const HomeBody1 = () => (
    <div className={`${styles.HomeBody1} ${homeStyles.wrapper}`}>
        <div className={"margin"}>
            <div className={`${styles.content} ${homeStyles.content}`}>
                <div className={`${styles.box} ${homeStyles.box}`}>
                    <img src={triangle} alt={"Triangle image"}/>
                </div>
                <div className={`${styles.text} ${homeStyles.text}`}>
                    <div className={styles.textContainer}>
                        <div className={styles.titleText}><Typography variant="h2">Heading 1</Typography></div>
                        <div className={styles.subText}>
                            <Typography variant="h5">
                                How promotion excellent curiosity yet attempted happiness. Gay prosperous impression had
                                conviction. For every delay death ask style. Me mean able my by in they. Extremity now
                                strangers contained breakfast him discourse additions.
                            </Typography>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
);


export default HomeBody1;
