import React, {useRef, useState} from 'react';
import styles from './SignUp.module.scss';
import authStyles from '../AuthenticatePage.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {Button, LinearProgress, TextField, Typography} from "@mui/material";
import SignInServ from "../../../../services/API_Resources/authentication/sign-in-service";
import loginGraphic from "../../../../assets/AuthenticationPages/createAccount.png"

const SignUp = () => {
        const formRef = useRef(null);


        async function handleSubmit(event) {
            event.preventDefault();

            const schoolID = event.target.elements.schoolID.value;
            const firstName = event.target.elements.firstName.value;
            const username = event.target.elements.username.value;
            const password = event.target.elements.password.value;

            console.log({
                SchoolID: schoolID,
                FirstName: firstName,
                Username: username,
                Password: password
            });


        }


        return (
            <div className={`${styles.SignUp} ${authStyles.mainBox}`}>
                <div className={authStyles.formBox}>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <Typography className={authStyles.title} variant="h4">Let's get started</Typography>
                        <div className={authStyles.accountType}>
                            <p>If you already have an account,&nbsp;
                                <span>
                                    <Link to="/login">Login.</Link>
                                </span>
                            </p>
                        </div>
                        <div className={`${authStyles.textbox} ${styles.inputs}`}>
                            <div>
                                <input id="schoolID"
                                       name="schoolID"
                                       placeholder="School ID"
                                       required
                                />

                                <input id="firstName"
                                       name="firstName"
                                       placeholder="First name"
                                       required
                                />
                            </div>
                            <input id="username"
                                   name="username"
                                   placeholder="Username"
                                   required
                            />

                            <input id="password"
                                   name="password"
                                   type="password"
                                   placeholder="password"
                                   required
                            />
                        </div>
                        <p className={authStyles.message}></p>
                        <div className={authStyles.submit}>
                            <button>Create Account</button>
                            {/*{isLoading && <LinearProgress color="primary"/>}*/}
                        </div>

                    </form>
                </div>
                <div className={authStyles.graphicBox}>
                    <div className={authStyles.imgPlaceholder}>
                        <img src={loginGraphic} alt={"Image of a student logging into a computer"}/>
                    </div>
                </div>
            </div>
        );
    }
;

export default SignUp;
