import React, {useState} from 'react';
import styles from './PlayPauseButton.module.scss';

const PlayPauseButton = () => {
    const [isPaused, setIsPaused] = useState(false);

    const handleClick = () => {
        setIsPaused(!isPaused);
    };

    return (
        <div className={styles.container}>
            <button
                className={`${styles.pauseplayBtn} ${isPaused ? styles.paused : ''}`}
                onClick={handleClick}
            >
                <div className={styles.d1}></div>
                <div className={styles.d2}></div>
            </button>
        </div>
    );
};

export default PlayPauseButton;
