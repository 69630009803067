import React from 'react';
import styles from './ModalSectionLabel.module.scss';
import {useColor} from "../../../../../ColorTheme/ThemeContext";

const ModalSectionLabel = ({name}) => {
    const {colors} = useColor();
    return (
        <div className={styles.ModalSectionLabel}>
            <h5 className={styles.title} style={{color: `rgba(${colors.accent}, 1)`}}>{name}</h5>
            <div className={"divider"}></div>
        </div>
    );
};

export default ModalSectionLabel;
