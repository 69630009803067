import React, {useState} from 'react';
import styles from './CheckinPage6.module.scss';
import {useColor} from '../../../ColorTheme/ThemeContext';
import parent from '../../DailyCheckinPages.module.scss';

const CheckinPage6 = ({onPageChange, onFormSubmit, currentData}) => {
    const [formValues, setFormValues] = useState({});
    const [pageNum] = useState(6)

    const pageState = state => {
        let page = pageNum;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = (event, state) => {
        event.preventDefault();
        onFormSubmit({[`page${pageNum}`]: formValues});
        pageState(state);
    };

    const classes = ['Math', 'Social Studies', 'English Language Arts', 'Science', 'PE', 'Elective']
    return (
        <div className={styles.CheckinPage1}>
            <div className={parent.margin}>
                <div className={parent.title}>
                    <h5>Which class are you doing best in this week?</h5>
                </div>
                <div className={parent.required}>
                    <div className="caption-text">Circle one</div>
                </div>
                <form onSubmit={(e) => handleSubmit(e, true)}>
                    <div className={`${parent.radioGroup} ${styles.group}`}>
                        {classes.map((option) => (
                            <label key={option} className={parent.container}>
                                <div className={parent.radioText}>{option}</div>
                                <input
                                    className={parent.radio}
                                    type="radio"
                                    name={"class"}
                                    value={option.toLowerCase()}
                                    onChange={handleChange}
                                    defaultChecked={currentData[`page${pageNum}`]?.class === option.toLowerCase()}
                                />
                                <span className={parent.checkmark}></span>
                            </label>
                        ))}
                    </div>
                    <div className={parent.buttonGroup}>
                        <button type={"button"} onClick={(e) => handleSubmit(e, false)}
                                className={parent.backButton}>Back
                        </button>
                        <button type="submit" className={parent.nextButton}>Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckinPage6;
