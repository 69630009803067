import React from 'react';
import styles from './ContactPage.module.scss';
import PublicHeader from "../components/PublicHeader/PublicHeader";

const ContactPage = () => (
    <div className={styles.ContactPage}>
        <PublicHeader pageName="Contact"/>
        ContactPage Component
    </div>
);

ContactPage.propTypes = {};

ContactPage.defaultProps = {};

export default ContactPage;
