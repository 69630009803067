import React from 'react';
import styles from './AccountModal.module.scss';
import PersonalSection from "./sections/PersonalSection/PersonalSection";

const AccountModal = () => (
    <div className={styles.AccountModal}>
        <PersonalSection/>
    </div>
);


export default AccountModal;
