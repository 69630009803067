import React, {useState} from 'react';
import styles from './BatteryLevel.module.scss';

const BatteryLevel = () => {

    const [getPercent, setPercent] = useState(0)
    const percent = () => {

    }

    return (
        <div className={styles.BatteryLevel}>
            <div className={styles.waveBase} style={{height: '24%'}}>
            </div>
        </div>
    );
};

export default BatteryLevel;
