import React from 'react';
import styles from './CheckinMessage.module.scss'
import parent from '../../DailyCheckinPages.module.scss'
import {useColor} from "../../../ColorTheme/ThemeContext";
import {Typography} from "@mui/material";

const CheckinMessage = ({onPageChange}) => {
    const {colors} = useColor();

    const pageState = state => {
        let page = 4;
        page = state ? page + 1 : page - 1;  // Use +1 and -1 explicitly
        onPageChange(page);
    };


    return (
        <div className={styles.CheckinWelcome} style={{backgroundColor: `rgba(${colors.primary}, .1)`}}>
            <div className={parent.centerMiddle}>
                <div>
                    <div className={styles.head}>
                        <Typography variant="h4">Since the previous question was filled out, please
                            continue.</Typography>
                    </div>
                    <div className={styles.para}>
                        <Typography variant="h6">You have a few more questions left.</Typography>
                    </div>

                    <div className={styles.para}>
                        <h6>You got this!</h6>
                    </div>
                    <div className={parent.letsGo} style={{color: `rgba(${colors.primary}, 1)`}}
                         onClick={() => pageState(true)}
                    >
                        <h4>Ok, Lets Go</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckinMessage;