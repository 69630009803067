import React, {useEffect, useState} from 'react';
import styles from './StudentPage.module.scss';
import StudentPageHead from "./StudentPageHead/StudentPageHead";
import OverviewSection from "./OverviewSection/OverviewSection";
import LessonsSection from "./LessonsSection/LessonsSection";
import dashboardService from "../../../../services/API_Resources/curriculum/dashboard-service";

const StudentPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dashboardService();
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    return (
        <div className={styles.StudentPage}>
            <StudentPageHead/>
            <OverviewSection/>
            <LessonsSection/>
        </div>
    );
};

export default StudentPage;
