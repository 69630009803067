import React from 'react';
import styles from './PictureBox.module.scss';
import parent from '../../../../Lessons/Lessons.module.scss'
import {useColor} from "../../../../../ColorTheme/ThemeContext";
import image from '../../../../../../../assets/course/Lesson1/activity1/image1.jpg'

const PictureBox = ({number}) => {
    const {colors} = useColor();

    const primary = `rgba(${colors.primary}, 1`;

    const getImagePath = (num) => {
        return require(`../../../../../../../assets/course/Lesson1/activity1/image${num}.jpg`);
    };

    return (
        <div style={{backgroundColor: `rgba(${colors.accent}, .2`}} className={styles.PictureBox}>
            <img className={styles.img} src={getImagePath(number)}
                 alt={"Lesson Graphic"}/>
            <div className={parent.rows}>
                <div>
                    <h5 style={{color: primary}}>Scenario {number}</h5>
                    <p>Debating me breeding be answered an he. Spoil event was words her off cause any. Tears woman</p>
                </div>
                <div>
                    <h5 style={{color: primary}}>Justin's Thoughts</h5>
                    <p>Debating me breeding be answered an he. Spoil event was words her off cause any. Tears woman</p>
                </div>
            </div>
        </div>
    )
}

export default PictureBox;