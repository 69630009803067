import React, {useEffect} from 'react';
import styles from './L1S1b.module.scss';
import Animation1 from "../../../../components/backgrounds/Animation1";
import parent from "../../../Lessons/Lessons.module.scss";
import {Link} from "react-router-dom";
import {getData} from "../../../../../../services/data/dataManager";
import {CATEGORIES} from "../../../../../../services/data/constants";

const L1S1b = ({thisPage, thisLesson}) => {
    const lesson = `lesson${thisLesson}`;
    const pageID = {
        section: 1,
        page: 2
    };
    useEffect(() => {
        thisPage(pageID);
    }, []);

    const handleCompletion = () => {
        const completion = getData(CATEGORIES.LESSONS)
        completion[lesson].pages[`page${pageID.section}/${pageID.page}`] = {isComplete: true}
    }
    return (
        <>
            <Animation1/>
            <div className={styles.L1S1b}>
                <div className={styles.wrapper}>
                    <h1>You have a chance to win $50</h1>
                </div>

                <div className={`${parent.buttonGroupRight} ${parent.buttonGroup}`}>
                    <Link to={"../a"} as={"button"}>
                        <button className={`${parent.backButton}`}>Back</button>
                    </Link>
                    <Link to={"../../section/2/a"} as={"button"}>
                        <button
                            onClick={handleCompletion}
                            className={`${parent.nextButton} button-ok-course`}>Next
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}


export default L1S1b;
