import React from 'react'
import styles from './AccessForbidden.module.scss'

const AccessForbidden = () => {
    return (
        <div className={styles.AccessForbidden}>
            Please Complete Lessons before moving forward
        </div>
    )
}

export default AccessForbidden;