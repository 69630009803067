import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HelpOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined'
import styles from './SidePanel.module.scss';
import BatteryLevel from "../components/BatteryLevel/BatteryLevel";
import {useColor} from "../../ColorTheme/ThemeContext";
import ModalBox from "../components/modals/ModalBox/ModalBox";
import {Link} from "react-router-dom";

const SidePanel = ({resizeState, onCallModal}) => {
    const [textDisplay, setTextDisplay] = useState("initial");
    const [textOpacity, setTextOpacity] = useState("initial");
    const [justifyContent, setJustifyContent] = useState("initial");
    const [width, setWidth] = useState("5%");
    const [right, setRight] = useState("initial");
    const [regularScreen, setRegularScreen] = useState(true);
    const {colors} = useColor();

    const updateScreenState = () => {
        const screenWidth = window.innerWidth;
        setRegularScreen(screenWidth >= 600);
    };


    useEffect(() => {
        document.querySelector(`.${styles.battery}`).style.backgroundColor = `rgba(${colors.primary}, .1)`;
        updateScreenState();

        const handleResize = () => {
            updateScreenState();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [resizeState, colors]);

    useEffect(() => {
        if (regularScreen) {
            if (resizeState) {
                setWidth("15%")
                setTextDisplay("initial");
                setJustifyContent("initial");
                const timeoutId = setTimeout(() => {
                    setTextOpacity("initial");
                }, 50);
                return () => clearTimeout(timeoutId);
            } else {
                setWidth("5%");
                setTextOpacity(0);
                const timeoutId = setTimeout(() => {
                    setJustifyContent('center');
                    setTextDisplay('none');
                }, 10);
                return () => clearTimeout(timeoutId);
            }
        } else {
            setWidth("initial")
            setTextDisplay("initial");
            setJustifyContent("initial");
            setTextOpacity("initial");
            if (resizeState) {
                setRight('0');
            } else {
                setRight("initial");
            }
        }
    }, [resizeState, regularScreen]);


    return (
        <div className={styles.SidePanel} style={{width: width, right: right}}>
            <div className={styles.batteryBox}>
                {/*<h6 className={styles.batteryMessage}>Battery Counter</h6>*/}
                <div className={styles.battery}>
                    <BatteryLevel/>
                </div>
            </div>
            <div className={styles.menuBox}>
                <Link to={"/dashboard"} className={styles.group} style={{justifyContent: justifyContent}}>
                    <div className={styles.icon}><GridViewIcon/></div>
                    <div className={styles.text} style={{opacity: textOpacity, display: textDisplay}}>Dashboard
                    </div>
                </Link>
                <div className={styles.group} onClick={() => onCallModal("account")}
                     style={{justifyContent: justifyContent}}>
                    <div className={styles.icon}><AccountCircleOutlinedIcon/></div>
                    <div className={styles.text} style={{opacity: textOpacity, display: textDisplay}}>Account</div>
                </div>
                <div className={styles.group} onClick={() => onCallModal("settings")}
                     style={{justifyContent: justifyContent}}>
                    <div className={styles.icon}><SettingsOutlinedIcon/></div>
                    <div className={styles.text} style={{opacity: textOpacity, display: textDisplay}}>Settings</div>
                </div>
                <div className={styles.group} style={{justifyContent: justifyContent}}>
                    <div className={styles.icon}><HelpOutlinedIcon/></div>
                    <div className={styles.text} style={{opacity: textOpacity, display: textDisplay}}>Help</div>
                </div>
                <div className={styles.group} style={{justifyContent: justifyContent}}>
                    <div className={styles.icon}><LogoutOutlinedIcon/></div>
                    <div className={styles.text} style={{opacity: textOpacity, display: textDisplay}}>Exit</div>
                </div>
            </div>
        </div>
    );
};

SidePanel.propTypes = {
    resizeState: PropTypes.bool.isRequired,
    onCallModal: PropTypes.func.isRequired,

};

export default SidePanel;
